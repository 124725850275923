import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Modal } from "react-responsive-modal"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PuppyTemplate = ({ pageContext, data }) => {
  const images = data.allFile.edges
  const primary = images.find(({ node }) => node.name === "primary")

  const [isOpen, setOpen] = React.useState(false)
  const [modalData, setModal] = React.useState({ title: "", html: "" })
  useEffect(() => {
    document.body.style.backgroundColor = "#a0a0a0"

    return () => (document.body.style.backgroundColor = "#fff")
  })
  return (
    <Layout footer={false}>
      <SEO title={`${pageContext.name}'s profile`} />
      <div className="paper">
        <div className="lines">
          <section className="section my-5">
            <div className="container is-centered">
              <div className="row ml-5">
                <div className="col-12 col-md-4">
                  <div className="mr-5 mt-5">
                    <h4
                      className="mt-0 text-center text-uppercase"
                      style={{
                        color: pageContext.available ? "blue" : "red",
                      }}
                    >
                      <span className="d-md-none">
                        {pageContext.available ? "Available" : "Reserved"}
                      </span>
                    </h4>
                    <Img
                      fluid={primary.node.childImageSharp.fluid}
                      className=""
                      alt="..."
                      width="100%"
                    />
                    <h3 className="mt-2 text-center ">{pageContext.name}</h3>
                    <button
                      className="btn btn-dark btn-md btn-block"
                      onClick={() => {
                        pageContext.available
                          ? setModal({
                              title: `${pageContext.name} is available!`,
                              html: `<p>It looks like ${pageContext.name} is available! If you would like the puppy to join your family, please contact us at <a href='mailto:sunnydoodlesaz@gmail.com'>sunnydoodlesaz@gmail.com</a>.</p>`,
                            })
                          : setModal({
                              title: "Would You Like To Join The Waitlist?",
                              html:
                                "<p>Oops! It looks like all our puppies are currently reserved. Would you like to join the waitlist to be notified as soon as we know there is another litter on the way?</p><p>If so, please contact us with your name, email, and the description of the puppy you would like at <a href='mailto:sunnydoodlesaz@gmail.com'>sunnydoodlesaz@gmail.com</a>.",
                            })
                        setOpen(true)
                      }}
                    >
                      RESERVE
                    </button>
                  </div>
                </div>
                <div className="col-12 col-md-8">
                  <h4
                    className="mt-0 text-center text-uppercase"
                    style={{
                      color: pageContext.available ? "blue" : "red",
                      width: "50%",
                    }}
                  >
                    <span className="d-none d-md-block">
                      {pageContext.available ? "Available" : "Reserved"}
                    </span>
                  </h4>
                  <p>
                    <span className="font-weight-bold">Breed:</span>{" "}
                    {pageContext.breed}
                  </p>
                  <p>
                    <span className="font-weight-bold ">Sex:</span>{" "}
                    <span className="text-capitalize">
                      {pageContext.gender}
                    </span>
                  </p>
                  <p>
                    <span className="font-weight-bold">Date of Birth:</span>{" "}
                    {pageContext.dob}
                  </p>
                  <div className="text">
                    <p>{pageContext.description}</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section my-5">
            <div className="container">
              <div className="row text-center ml-4">
                <div className="col-sm-3 mb-5">
                  <button
                    className="btn btn-primary"
                    style={{ height: "100%" }}
                    onClick={() => {
                      setModal({
                        title: "Birth Records",
                        html: `${pageContext.name} is a ${
                          pageContext.gender
                        } goldendoodle puppy born on ${pageContext.dob} at ${
                          pageContext.dobTime
                        } weighing ${pageContext.weight[0].weight} oz. ${
                          pageContext.name
                        } was the ${
                          pageContext.litterNumber
                        } puppy to be born in ${
                          pageContext.gender === "male" ? "his" : "her"
                        } litter. At birth ${
                          pageContext.gender === "male" ? "he" : "she"
                        } was given the ${pageContext.collarColor} collar.`,
                      })
                      setOpen(true)
                    }}
                  >
                    Birth Record
                  </button>
                </div>
                <div className="col-sm-3 mb-5">
                  <button
                    className="btn btn-primary"
                    style={{ height: "100%" }}
                    onClick={() => {
                      setModal({
                        title: "Weight Record",
                        html: "<p>Weight record coming soon</p>",
                      })
                      setOpen(true)
                    }}
                  >
                    Weight Record
                  </button>
                </div>
                <div className="col-sm-3 mb-5">
                  <button
                    className="btn btn-primary"
                    style={{ height: "100%" }}
                    onClick={() => {
                      setModal({
                        title: "Vet Records Coming Soon!",
                        html:
                          "<p>Puppies will be visiting the vets on August 1st 2020.</p>",
                      })
                      setOpen(true)
                    }}
                  >
                    Vet Visits
                  </button>
                </div>
                <div className="col-sm-3 mb-5">
                  <button
                    className="btn btn-primary"
                    style={{ height: "100%" }}
                    onClick={() => {
                      setModal({
                        title: "Shot Records Coming Soon!",
                        html:
                          "<p>Puppies will be visiting the vets on August 1st 2020.</p>",
                      })
                      setOpen(true)
                    }}
                  >
                    Shots
                  </button>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="container">
              <div className="row ml-4">
                {images.map(image => (
                  <div className="col-sm-4 mb-4" key={image.node.id}>
                    <Img
                      fluid={image.node.childImageSharp.fluid}
                      className=""
                      alt="..."
                    />
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
        <div className="holes hole-top"></div>
        <div className="holes hole-middle"></div>
        <div className="holes hole-bottom"></div>
      </div>
      <Modal
        open={isOpen}
        onClose={() => setOpen(false)}
        center
        style={{ zIndex: 100 }}
      >
        <h2>{modalData.title}</h2>
        {console.log("fff", modalData.html)}
        <div dangerouslySetInnerHTML={{ __html: modalData.html }} />
      </Modal>
    </Layout>
  )
}

export const query = graphql`
  query($profileImages: String) {
    allFile(
      filter: { relativeDirectory: { eq: $profileImages } }
      sort: { fields: name, order: DESC }
    ) {
      edges {
        node {
          name
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default PuppyTemplate
